<template>
  <div id="app" :class="{ 'app1': isMobileDevice, 'agreement': ['privacy', 'terms'].includes($route.name) || isMobileDevice }">
    <srcollTop></srcollTop>
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      isMobileDevice: ''
    }
  },
  created () {
    this.isMobileDevice = window.matchMedia('(max-width: 767px)').matches
  }
}
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1B2E2C;
  margin-top: 3.5rem;
}
.agreement {
  margin-top: 1.5rem !important;
}
.app1 {
  padding-top: 0 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>

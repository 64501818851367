<template>
  <div>
    <!-- 底部 -->
    <footer>
      <div class="content">
        <div class="left" @click="goHome">
          <img
            src="@/assets/PC/底部logo.png"
            alt=""
            style="width: 0.62rem; height: 0.72rem"
          />
          <span @click="toPath('home')">Cloudun AI</span>
        </div>
        <div class="right">
          <ul class="footerTitle">
            <li>
              <h4 @click="toPath('ads')">{{ $t("route.productMatrix") }}</h4>
              <p class="item" @click="toPath('ads')" v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'">
                <span
                  >
                  {{ $t("route.productAITitle1") }}
                  {{ $t("route.productAITitle2") }}
                  </span
                >
              </p>
              <p class="item" @click="toPath('ekyc')" v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'">
                <span
                  >{{ $t("route.productNumberTitle2")
                  }}{{ $t("route.productNumberTitle1") }}</span
                >
              </p>
              <p class="item" @click="toPath('credit')">
                <span
                  >{{ $t("credit.productCreditTitle2")
                  }}{{ $t("credit.productCreditTitle1") }}</span
                >
              </p>
              <p class="item" @click="toPath('consult')" v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'">
                <span
                  >{{ $t("credit.productCreditTitle2")
                  }}{{ $t("route.produCtonsultTitle1") }}</span
                >
              </p>
            </li>
            <li v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'">
              <h4 @click="toPath('practice')">
                {{ $t("route.BestPractices") }}
              </h4>
              <p class="item" @click="toPath('practice', 'mode')">
                <span>{{ $t("route.solve") }}</span>
              </p>
              <p class="item" @click="toPath('practice', 'application')">
                <span>{{ $t("route.industry") }}</span>
              </p>
            </li>
            <li>
              <h4 @click="toPath('aboutUs')">{{ $t("route.Aboutus") }}</h4>
              <p class="item" @click="toPath('aboutUs')">
                <span>{{ $t("route.CompanyProfile") }}</span>
              </p>
              <p class="item" @click="toPath('aboutUs', 'course')">
                <span>{{ $t("route.development") }}</span>
              </p>
              <p class="item" @click="$router.push({name: 'privacy'})">
                <span>{{ $t("useCookie.privacy") }}</span>
              </p>
              <p class="item" @click="$router.push({name: 'terms'})">
                <span>{{ $t("useCookie.terms") }}</span>
              </p>
            </li>
            <li>
              <h4>{{ $t("route.cellWe") }}</h4>
              <p class="item">
                <img src="@/assets/PC/icon-mail.png" alt="" />
                <span
                  >{{ $t("route.business") }}<br />operation@cloudun.ai</span
                >
              </p>
              <p
                class="item"
                v-if="$i18n.locale === 'en' || $i18n.locale === 'id'"
              >
                <img src="@/assets/PC/icon-phone.png" alt="" />
                <span>{{ $t("route.customer") }}<br />+62 877 75637420</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="text">Copyright © 2023 Cloudun AI. All Rights Reserved.</div> -->
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footerPage',
  methods: {
    goHome () {
      if (this.$route.path !== '/home') {
        localStorage.setItem('pageShow', JSON.stringify('product'))
        this.$router.push('/')
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    },
    // 个别区域跳位置需要精确
    determine (distance) {
      const modeValue =
        parseFloat(getComputedStyle(document.documentElement).fontSize) * 7.9
      const applicationValue =
        parseFloat(getComputedStyle(document.documentElement).fontSize) * 25.34
      const courseValue =
        parseFloat(getComputedStyle(document.documentElement).fontSize) * 10.5

      switch (distance) {
        case 'mode':
          window.scrollTo(0, modeValue)
          return
        case 'application':
          window.scrollTo(0, applicationValue)
          return
        case 'course':
          window.scrollTo(0, courseValue)
      }
    },
    // 底部跳转事件
    toPath (path, distance) {
      if (['ads', 'ekyc', 'credit', 'consult'].includes(path)) {
        localStorage.setItem('pageShow', JSON.stringify('product'))
      } else {
        localStorage.setItem('pageShow', JSON.stringify(path))
      }
      if (this.$route.path === `/${path}`) {
        window.scrollTo(0, 0)
        this.determine(distance)
        return
      }
      this.$router
        .push({
          path: `/${path}`
        })
        .then(() => {
          window.scrollTo(0, 0)
          this.determine(distance)
        })
    }
  }
}
</script>

<style scoped lang="less">
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
img {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
// 底部
footer {
  height: 30vh;
  background-color: #002a2b;
  box-sizing: border-box;
  padding: 0.66rem 0;
  cursor: pointer;
  .content {
    width: 1100px;
    margin: auto;
    box-sizing: border-box;
    border-bottom: 1px solid #003536;
    display: flex;
    justify-content: space-between;
    .left {
      height: 0.72rem;
      margin-right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: "Microsoft YaHei";
        margin-left: 5px;
        font-size: 16px;
        color: #fff;
        width: 100px;
        font-weight: 600;
        &:hover {
          color: #80c3b9;
        }
      }
    }
    .right {
      .footerTitle {
        display: flex;
        li {
          width: 4rem;
          text-align: left;
          &:nth-child(4) {
            width: 4.5rem;
          }
          h4 {
            font-size: 0.28rem;
            color: #fff;
            margin-bottom: 0.4rem;
          }
          .item {
            color: #c5c5c5;
            font-size: 0.28rem;
            line-height: 1.7;
            margin-top: 0.2rem;
            span {
              &:hover {
                color: #8fc1b9;
              }
            }
            img {
              width: 0.24rem;
              height: 0.22rem;
            }
          }
        }
      }
    }
  }
  .text {
    color: #ffffff;
    font-size: 0.24rem;
    transform: scale(0.8);
  }
}
</style>

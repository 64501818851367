export default {
  route: {
    //   banner1
    Slogan1: 'AI和算法赋能',
    Slogan1a: '数字借贷',
    introduction: '全球数字借贷领域中领先的科技公司，通过人工智能和算法科技，为企业客户提供数智化、市场营销、风险管理和商业咨询等服务，帮助企业提高其金融服务能力和效率',
    characteristica: '深刻的行业理解与趋势前瞻',
    characteristicb: '领先的智能科技技术',
    characteristicc: '资深的本地化运营经验',
    characteristicd: '专业高效的团队和服务',
    // banner2
    Slogan2: '我们能为行业',
    Slogan2a: '提供什么',
    characteristic1: '不断的技术创新带来风险管理升级',
    characteristic2: '深谙本地市场助力合规经营',
    characteristic3: '专业的商业洞察，提升收益',
    characteristic4: '降本提效、数智化转型',
    // ai数字投放
    aiTab: 'AI数字投放',
    aiSlogan: '算法驱动创造力成就业务增长',
    productFeatures: '产品特色',
    aiFeatures1: '基于最佳实践的定制化增长方案',
    aiFeatures2: '创新AIGC技术，海量素材高效生产',
    aiFeatures3: '领先的算法实现量与质的螺旋上升',
    aiFeatures4: '多维度数据分析带来更深度的洞察',
    estimate: '评估、制定计划',
    aiLeftListaOne: '诊断并发现当前问题',
    aiLeftListTwo: '通过机器学习评分实现ASO，找到对客户最适合的广告素材',
    aiLeftCenter: '实验期广告',
    aiCenterList1: '制作适合的广告素材，如关键词、图片等;',
    aiCenterList2: '调整出价策略，在最短的时间内累积样本，并通过机器学习优化广告算法。',
    Acquiring: '获客增长',
    aiRightList1: '通过AI技术生成更多的广告素材；',
    aiRightList2: '获客增长，并不断优化广告推荐算法和广告素材。',
    buttonStr: '查看详情',
    // 数字身份识别
    numberTab: '数字身份识别',
    numberSlogan: '体验先进、安全、流畅的 EKYC 服务',
    numberFeatures1: '领先业界的OCR和活体检测技术，高精准识别',
    numberFeatures2: '流畅便捷的用户旅程',
    numberFeatures3: '人工智能反欺诈，保障业务免遭损失',
    numberLeftTitle1: '图片类：',
    numberLeftIcon1: '自拍照',
    numberLeftIcon2: '身份证',
    numberLeftTitle2: '文本类：',
    numberLeftIcon3: '设备ID',
    numberLeftIcon4: '电话号码',
    numberLeftIcon5: '银行卡',
    numberLeftIcon6: '姓名',
    numberLeftIcon7: '身份证号',
    numberCenterStr1: '采集',
    numberCenterStr2: '验证',
    numberRightIcon1: '人脸比对',
    numberRightIcon2: '人脸搜索',
    numberRightIcon3: '证件对比',
    numberRightIcon4: '银行卡验证',
    numberRightIcon5: '设备ID验证',
    numberRightIcon6: '电话号码验证',
    // 云盾信用分
    creditTab: '云盾信用分',
    creditSlogan: '可靠的风险洞察带来业务的蓬勃增长',
    creditFeatures1: '丰富的实践经验',
    creditFeatures2: '前沿的算法能力',
    creditFeatures3: '海量模型，多样化的评分',
    creditFeatures4: '稳定的风险表现',
    creditLeftStr1: '电话',
    creditLeftStr2: '姓名',
    creditLeftStr3: '身份证号',
    creditLeftStr4: '用户行为',
    creditRightStr1: '黑名单查询',
    creditRightStr2: '定制特征',
    creditRightStr3: '预审',
    creditRightStr4: '定向决策',
    creditRightStr5: '综合决策',
    // 云盾咨询
    consultTab: '云盾咨询',
    consultSlogan: '与我们一起实现利润增长',
    consultFeatures1: '当地合规政策的深刻理解',
    consultFeatures2: '丰富的本地化运营经验',
    consultFeatures3: '专业的产品设计与运营增长能力',
    consultFeatures4: '灵活的“组件化”定制服务模式',
    consultFeatures5: '量化经营管理，数据驱动增长',
    consultStr1: '商业咨询',
    consultStr2: '部署实施',
    consultStr3: '风险管理',
    consultStr4: '运营指导',
    consultStr5: '经营分析',
    consultStr6: '增值服务',
    // 行业应用区
    all: '全部',
    advertisement: '广告投放',
    digitalIntelligenceUserExperience: '数智化用户体验',
    riskManagement: '风险管理',
    compliantOperationsAndBusinessGrowth: '合规经营与商业增长',
    // 行业应用数据简介
    industryApplications1: '算法优化ASO，提升投放的量与质',
    industryApplications2: 'EKYC，远程身份认证与证件识别，线上完成交易',
    industryApplications3: '人脸搜索黑名单，海量数据库，识别“风险人”',
    industryApplications4: '人脸比对，精准识别“同一人”，防范欺诈风险',
    industryApplications5: '多维度海量数据，全方位风险防范',
    industryApplications6: '多头和共债特征识别，用户全生命周期跟踪把控，实现风险预测',
    industryApplications7: '定制化特征输出，适配不同类型风险管理需要',
    industryApplications8: '信用评分多场景适配，利益最大化的同时严防违约风险',
    industryApplications9: '深谙行业，本地化的商业解决方案',
    industryApplications10: '合规、流畅、高转化的用户端产品设计',
    industryApplications11: '数据驱动的业务运营跟踪，周期性的经营分析复盘',
    industryApplications12: '全域数据采集，洞察现状、异动预警、成就增长',
    // 行业应用标签
    tab1: '#AIGC',
    tab2: '#广告投放',
    tab3: '#OCR识别',
    tab4: '#活体检测',
    tab5: '#EKYC',
    tab6: '#人脸搜索黑名单',
    tab7: '#人脸比对',
    tab8: '#黑名单',
    tab9: '#特征实验室',
    tab10: '#多头/共债识别',
    tab11: '#定制特征',
    tab12: '#信用评分',
    tab13: '#风险决策',
    tab14: '#商业咨询',
    tab15: '#数字贷款解决方案',
    tab16: '#本地化',
    tab17: '#商业分析',
    tab18: '#经营分析',
    tab19: '#可视化报表',
    tab20: '#数智化运营',
    // navigtor导航栏
    home: '首页',
    Partners: '合作伙伴',
    productMatrix: '产品矩阵',
    BestPractices: '最佳实践',
    Aboutus: '关于我们',
    News: '新闻',
    freeTrial: '免费试用',
    // Chinese: '中文',
    // English: '英文',
    // Mexico: '墨西哥',
    // Indonesia: '印尼',
    download: '扫码下载试用',
    // 底部选项
    solve: '解决方案模式',
    industry: '行业应用',
    Rating: '云盾信用评分',
    CompanyProfile: '公司简介',
    development: '公司历程',
    cellWe: '联系我们',
    business: '业务咨询：',
    // 最佳实践
    headline: '全球信赖的领先技术合作伙伴',
    subtitle1: '专业',
    subtitle2: '方案、',
    subtitle3: '高效',
    subtitle4: '服务',
    mode1: '云端调用',
    modeContent1: '通过API接口形式，按需调用多项数据服务。适 用产品:EKYC、黑名单、特征、综合评分等。',
    mode2: '私有化部署',
    modeContent2: '定制开发部署，实现代码完整移交与交付培训。适用产品:商业咨询',
    mode3: '全权代理',
    modeContent3: '适用产品:AI数字广告; 全面负责账户开立、素材生成与管理，广告策 略实施与优化。',
    // 关于我们
    CompanyProfile1: 'Cloudun AI于2019年在上海成立，是一家专注于“决策科学”商用的市场科技公司，集科技、客户洞察、 金融场景、产品运营于一体，帮助传统金融企业适应大数据时代的新变化，运用“决策科学”技术为传 统企业在大数据的时代环境下进行赋能。',
    CompanyProfile2: 'Cloudun AI的团队早期均在国内知名新金融公司任职，对金融技术和产品有深刻的专业见解，客户需求 及客户分层定位有专业的理解和分析，在风控管理领域有扎实的理论基础和丰富的实践经验。 我们致力于为传统企业在大数据时代提供全方位、全流程的“决策”能力，无论其是否具有大数据基础， 通过简单、强大和自动化的方法，助力所有技能水平的分析团队成员处理分析生命周期中的所有任务。 目前，Cloudun AI已经为印尼、泰国、尼日利亚和墨西哥的客户开展了服务。',
    new2018StrHeader: '上海奋钛数科',
    new2018StrCenter: '成立, 主要从事SaaS服务;',
    new2019Str1Header: '云盾开曼和云盾新加坡',
    new2019Str1Center: '于2019年5月成立;',
    new2019Str2: '云盾集团开始在印尼尝试展开金融科技领域AI与技术;',
    new2020StrHeader: '云盾印尼',
    new2020StrCenter: '成立， 正式在印尼推出EKYC、信用评分、AI数字投放、商业咨询服务;',
    new2020Str1Header: '截止2020年，云盾已为37家企业客户提供服务，当年累计产品调用量达',
    new2020Str1Center: '4千万次。',
    new2021Str1Header: '云盾香港',
    new2021Str1Center: '成立，同年在墨西哥开展业务，并推出全新产品线: 数字投放 ;',
    new2021Str2Header: '截止2021年底，云盾以为67家企业客户提供服务，当年累计产品调用量超',
    new2021Str2Center: '9千万次。',
    new2022Str1Header: '云盾集团',
    new2022Str1Center: '在印尼及墨西哥市场持续优化产品及服务，保持着服务客户数和利润的稳定增长，2023年全年产品调用量超',
    new2022Str1footer: '2.7亿次；',
    new2022Str2Header1: '截止2023年12月，云盾已为超过',
    new2022Str2Center1: '90家',
    new2022Str2Header2: '的企业客户提供服务，累计服务约',
    new2022Str2Center2: '30亿美金',
    new2022Str2Header3: '金融交易额，触达C端用户超过',
    new2022Str2Center3: '5千万；',
    new2022Str3: '完成A轮融资。',
    new2024Str1Center1: '在保持存量市场稳定增长的基础上，',
    new2024Str1Header: '云盾集团',
    new2024Str1Center2: '将从横向探索如菲律宾等其他地区市场，从纵向推进例如智能语音系统等智能运营系统的开发；',
    new2024Str2: '积极深耕产业链投资，寻找机会发挥价值链上的协同效应',
    // 产品二级页
    // ai数字广告
    productAITitle: 'AI数字广告',
    productAITitle1: 'AI',
    productAITitle2: '数字广告',
    productAISubTitle: '算法驱动创造力成就业务增长',
    productAIMode1: '借助AI来生成和衍生广告投放素材；',
    productAIMode2: '通过算法持续优化广告算法以提升投放效果；',
    productAIMode3: '帮助企业客户大 规模获得意向用户的同时，将投放成本控制在最优的范围内。',
    productAIHighlights1: '如何出价毫无头绪',
    productAIHighlightScorrect1: '机器学习算法调整出策略',
    productAIHighlights2: '素材产出慢、效果差',
    productAIHighlightScorrect2: '算法寻找最优素材，AIGC实现素材沉淀与衍生，可短时间产出大量素材',
    recommendProduct: '这些企业都在用',
    // 数字身份识别
    productNumberTitle1: '身份识别',
    productNumberTitle2: '数字',
    productNumberSubTitle: '体验先进、安全、流畅的EKYC服务',
    productNumberMode1: '高效采集身份信息，精准识别是否为“真实人”、“同一人”、“风险人”',
    productNumberMode2: '便捷验证身份，精准识别风险',
    productNumberHighlightScorrect1: '提交负责纸质表单或面签以完成交易',
    productNumberHighlightScorrect2: '需要复杂的人工认证流程',
    productNumberHighlightScorrect3: '通过证件识别和活体认证，一流的数字登陆体验',
    productNumberHighlightScorrect4: '多维度信息采集，高效识别欺诈风险',
    // 信用评分
    productCreditTitle: '云盾信用评分',
    productCreditMode1: '依托丰富的实践经验、前沿的算法能力，自主研发可灵活配置的风险管理模型，实时调用并反馈，助力合作伙伴高效、准确做出贷款决策。',
    productCreditMode2: '产品细分包括特征实验室和综合评分。',
    productCreditHighlights1: '如何更全面的进行欺诈识别',
    productCreditHighlightScorrect1: '多维度海量数据黑名单查询',
    productCreditHighlights2: '如何升级风控策略',
    productCreditHighlightScorrect2: '定制化特征，定向输出',
    productCreditHighlights3: '风控效果一直无法提升',
    productCreditHighlightScorrect3: '综合决策，可靠的信用洞察',
    productCreditHighlights4: '误拒绝了一些用户造成了流失',
    productCreditHighlightScorrect4: '定向决策，用户留存与利润挽回',
    // 云盾咨询
    produCtonsultTitle: '云盾商业咨询',
    produCtonsultTitle1: '商业咨询',
    produCtonsultTitle2: '云盾',
    produCtonsultSubTitle: '与我们一起见证利润增长',
    produCtonsultMode1: '从前期商务咨询建立业务模式、定制开发部署，到业务初期辅导运营、经营分析，最终实现运营策略与代码完整移交，实现业务全链路解决方案。同支持三方服务生态供给，为业务持续运营、盈利增长负责。',
    produCtonsultHighlights1: '新开拓市场不知如何本地化落地',
    produCtonsultHighlightScorrect1: '深谙行业，本地化的商业解决方案',
    produCtonsultHighlights2: '新客转化低、老客留存低',
    produCtonsultHighlightScorrect2: '合规、流畅、高转化的用户端产品设计',
    produCtonsultHighlights3: '持续亏损或利润低迷',
    produCtonsultHighlightScorrect3: '全链路商业运营解决方案，全程护航',
    // 新增词汇
    statusTitle: '行业现状',
    advantageTitle: '方案优势',
    modeTitle: '模式介绍',
    headline1: '全球信赖的',
    headline2: '领先技术合作伙伴',
    organizationStructure: '组织架构',
    certificate: '认证和许可证',
    progress: '进行中',
    registered: '已申请',
    address: '公司地址',
    location: '地点',
    latest: '最新发布',
    next: '下一篇',
    privacy: '隐私政策',
    terms: '服务条款'
  },
  credit: {
    productCreditTitle1: '信用评分',
    productCreditTitle2: '云盾',
    productCreditSubTitle: '可靠的风险洞察带来业务的蓬勃增长'
  },
  useCookie: {
    privacy: '隐私政策',
    terms: '服务条款',
    navigtorCookie: '本网站使用cookie。继续浏览该网站即表示您同意我们使用cookie。有关cookie以及如何管理它们的更多详细信息, 请参见我们的',
    accept: '接受'
  }
}

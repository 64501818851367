import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routesPC = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/PC/home/home.vue')
  },
  {
    path: '/practice',
    component: () => import('@/views/PC/practice/index.vue')
  },
  {
    path: '/ads',
    component: () => import('@/views/PC/home/components/aiAds.vue')
  },
  {
    path: '/ekyc',
    component: () => import('@/views/PC/home/components/EKYC.vue')
  },
  {
    path: '/credit',
    component: () => import('@/views/PC/home/components/credit.vue')
  },
  {
    path: '/zhCredit',
    component: () => import('@/views/PC/home/components/zhCredit.vue')
  },
  {
    path: '/consult',
    component: () => import('@/views/PC/home/components/consult.vue')
  },
  {
    path: '/aboutUs',
    name: 'us',
    component: () => import('@/views/PC/home/components/aboutUs.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/PC/home/components/news.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PC/agreement/privacy.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/PC/agreement/terms.vue')
  }
]
const routesMobile = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/mobile/home.vue')
  },
  {
    path: '/practice',
    name: 'Practice',
    component: () => import('@/views/mobile/practice.vue')
  },
  {
    path: '/practice',
    name: 'Industry',
    component: () => import('@/views/mobile/practice.vue'),
    meta: {
      scroll: 'Industry'
    }
  },
  {
    path: '/aiAds',
    name: 'AiAds',
    component: () => import('@/views/mobile/aiAds.vue')
  },
  {
    path: '/ekyc',
    name: 'EKYC',
    component: () => import('@/views/mobile/EKYC.vue')
  },
  {
    path: '/credit',
    name: 'Credit',
    component: () => import('@/views/mobile/credit.vue')
  },
  {
    path: '/zhCredit',
    name: 'zhCredit',
    component: () => import('@/views/mobile/zhCredit.vue')
  },
  {
    path: '/consult',
    name: 'Consult',
    component: () => import('@/views/mobile/consult.vue')
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('@/views/mobile/aboutUs.vue')
  },
  {
    path: '/aboutUs',
    name: 'Course',
    component: () => import('@/views/mobile/aboutUs.vue'),
    meta: {
      scroll: 'Course'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/mobile/news.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/mobile/agreement/privacy.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/mobile/agreement/terms.vue')
  }
]

const isMobile = window.matchMedia('(max-width: 767px)').matches
const routes = isMobile ? routesMobile : routesPC
const router = new VueRouter({ routes })
export default router
